@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  overflow-y: hidden;
  /* background-color: #F4F3F9; */
}


*{box-sizing: border-box;}
