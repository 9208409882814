.cover {
  height: 210px;
  width: 841px;
  border-bottom: 8px solid;
  color: white;
  padding: 20px;
  position: relative;
}

.name {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.headline {
  font-size: 12px;
  font-weight: 300;
  // text-transform: lowercase;
  max-width: 330px;
}

.rightContent {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 40px);
  max-width: 406px;
}

.tagline {
  font-size: 26px;
  font-weight: 500;
  text-align: right;
}

.highlights {
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: right;
  padding-left: 50px;
}



@media (max-width:865px){
  .cover {
    height: 210px;
    width: 100%;
  }
  .name {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .tagline {
    font-size: 1.3rem;
    font-weight: 400;
  }
  .headline {
    font-size: 1.1rem;
  }
  
.highlights {
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: right;
  padding-left: 50px;
}

}



@media (max-width:470px){
  .cover {
    height: 210px;
    width: 100%;
  }
  .name {
    font-size: 1rem;
  }
  .tagline {
    margin-top:50px;
    font-size: 1.2rem;
  }
  .headline {
    font-size: 0.8rem;
  }
  .highlights {
    font-size: 0.8rem;

  }
}



@media (max-width:322px){
  .cover {
    height: 210px;
    width: 100%;
  }
  .name {
   
    font-size: 1rem;
  }
  .tagline {
    margin-top:50px;
    font-size: 1.3rem;
  }
  .headline {
    font-size: 0.8rem;
  }
  .highlights {
    font-size: 0.8rem;
  }
}
