.coverWrapper {
  background: #BB8FCE; /* fallback for old browsers */
  background: linear-gradient(to right, #F7DC6F, #ABEBC6);
  height: 270px;
  width: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 10;
  padding: 30px 0;
}

.scrollContainer {
  height: calc(100vh - (270px + 60px));
  overflow-y: scroll;
  padding: 30px 0 30px 14px;
  background-color: white;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
  display: block;
  text-transform: capitalize;
}

.input {
  height: 46px;
  width: 100%;
  outline: 0;
  border: 0;
  border-left: 6px solid #7d58fe;
  border: 1px solid rgb(232, 232, 232);
  padding: 0 14px;
  border-radius: 4px;
  background-color: rgb(244, 244, 244);
  margin-bottom: 20px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02); */
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.footer {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 10px;
  background: #141e30; /* fallback for old browsers */
  background: linear-gradient(to right, #243b55, #141e30);
  color: white;
  z-index: 10;

  a {
    color: inherit;
  }
}

.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.button {
  display: inline-flex;
  padding: 8px 14px;
  font-size: 12px;
  color: black;
  // background-color: #7d58fe;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;

  &:hover {
    background-color: #04CBFC;
  }
}

@media only screen and (max-width: 841px) {
  .coverWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .scrollContainer {
    padding: 20px 6px;
  }
}
