@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  overflow-y: hidden;
  /* background-color: #F4F3F9; */
}


*{box-sizing: border-box;}

.color-blocks_title__AHiLj{margin:0;color:#333;margin-left:30px}.color-blocks_container__2nhxC{background-color:white;padding:20px 0 20px 10px;border-radius:10px;border:1px solid #e8e8e8;display:inline-flex}.color-blocks_rail__3qAbc{display:flex}

.app_coverWrapper__1c6uG{background:#BB8FCE;background:linear-gradient(to right, #F7DC6F, #ABEBC6);height:270px;width:100%;padding:30px 0}.app_scrollContainer__3A9P_{height:calc(100vh - (270px + 60px));overflow-y:scroll;padding:30px 0 30px 14px;background-color:white}.app_label__dXJ3T{font-size:14px;font-weight:500;color:#333;margin-bottom:4px;display:block;text-transform:capitalize}.app_input__2EpoL{height:46px;width:100%;outline:0;border:0;border-left:6px solid #7d58fe;border:1px solid #e8e8e8;padding:0 14px;border-radius:4px;background-color:#f4f4f4;margin-bottom:20px;font-size:15px;font-weight:500;color:#333}.app_footer__2vzCe{height:60px;position:fixed;bottom:0;left:0;width:100%;font-size:10px;background:#141e30;background:linear-gradient(to right, #243b55, #141e30);color:white;z-index:10}.app_footer__2vzCe a{color:inherit}.app_flex__13jAJ{width:100%;display:flex;justify-content:space-between;align-items:center;height:60px}.app_button__hx0oF{display:inline-flex;padding:8px 14px;font-size:12px;color:black;background-color:white;border-radius:2px;cursor:pointer;text-transform:uppercase;font-weight:500}.app_button__hx0oF:hover{background-color:#04CBFC}@media only screen and (max-width: 841px){.app_coverWrapper__1c6uG{overflow-x:scroll;overflow-y:hidden}.app_scrollContainer__3A9P_{padding:20px 6px}}

.cover_cover__2qARQ{height:210px;width:841px;border-bottom:8px solid;color:white;padding:20px;position:relative}.cover_name__2gdvZ{font-size:16px;text-transform:uppercase;font-weight:600}.cover_headline__12Tnq{font-size:12px;font-weight:300;max-width:330px}.cover_rightContent___VTeK{position:absolute;top:20px;right:20px;display:flex;flex-direction:column;justify-content:space-between;height:calc(100% - 40px);max-width:406px}.cover_tagline__1wzBX{font-size:26px;font-weight:500;text-align:right}.cover_highlights__2OyWy{font-size:1rem;font-weight:400;text-transform:capitalize;text-align:right;padding-left:50px}@media (max-width: 865px){.cover_cover__2qARQ{height:210px;width:100%}.cover_name__2gdvZ{font-size:1.1rem;font-weight:400}.cover_tagline__1wzBX{font-size:1.3rem;font-weight:400}.cover_headline__12Tnq{font-size:1.1rem}.cover_highlights__2OyWy{font-size:1rem;font-weight:400;text-transform:capitalize;text-align:right;padding-left:50px}}@media (max-width: 470px){.cover_cover__2qARQ{height:210px;width:100%}.cover_name__2gdvZ{font-size:1rem}.cover_tagline__1wzBX{margin-top:50px;font-size:1.2rem}.cover_headline__12Tnq{font-size:0.8rem}.cover_highlights__2OyWy{font-size:0.8rem}}@media (max-width: 322px){.cover_cover__2qARQ{height:210px;width:100%}.cover_name__2gdvZ{font-size:1rem}.cover_tagline__1wzBX{margin-top:50px;font-size:1.3rem}.cover_headline__12Tnq{font-size:0.8rem}.cover_highlights__2OyWy{font-size:0.8rem}}

.colorblock_button__2g_NY{height:40px;width:40px;position:relative;border:6px solid;border-radius:20px;cursor:pointer;margin-right:20px;transition:0.2s ease-out}.colorblock_button__2g_NY:hover{-webkit-transform:scale(1.3);transform:scale(1.3);transition:0.2s ease-in}

