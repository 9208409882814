.title{
    margin:0;
    color: #333;
    margin-left:30px;
}

.container{
    background-color:white; 
    padding:20px 0 20px 10px; 
    border-radius:10px; 
    border:1px solid rgb(232,232,232);
    display: inline-flex;
}

// .railContainer{
//     overflow-x:scroll;
// }

.rail{
    display:flex;
}