.button{
    height:40px;
    width:40px; 
    position: relative;
    border:6px solid;
    border-radius:20px;
    cursor:pointer;
    margin-right:20px;
    transition: 0.2s ease-out;

    &:hover{
        transform: scale(1.3);
        transition: 0.2s ease-in;
    }
}